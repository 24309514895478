#restaurant-interface {
  background-color: #efefef;

  img {
    width: inherit;
    padding: 10px;
    height: 100px;
  }
  > nav {
    background-color: #22223b;
    color: white;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    width: 100px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    > ul {
      list-style-type: none;
      margin-left: 0;
      padding-left: 0;
      li {
        margin-bottom: 20px;
        opacity: 0.4;
        display: flex;
        &.active {
          opacity: 1;
        }
        .select-indicator {
          width: 5px;
          background-color: white;
          height: 50px;
          position: absolute;
          left: 0;
        }
      }

      svg {
        width: 40px;
        height: 50px;
      }
    }
    > :last-child {
      margin-top: auto;
      margin-bottom: 20px;
      &.info {
        > .current-time {
          font-size: 2rem;
          margin-bottom: 15px;
          margin-top: 15px;
        }
        > span {
          opacity: 0.6;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  > .content {
    margin-left: 100px;
  }
}

.toast-order-creation {
  display: flex;
  align-items: center;
  > :nth-child(n) {
    margin: 0 0.5rem;
  }

  .order-number {
    font-size: 3rem;
    color: black;
  }

  .message {
    font-size: 1.2rem !important;
  }
}

.order-received-toast {
  background: #e4e4ea !important;
  border: 2px solid #9797df;

  &.new {
    background-color: #d2e4d2 !important;
    border: 2px solid #23a833;
  }
  &.update {
    background-color: #e0d9b4 !important;
    border: 2px solid #c0a818;
  }
  &.cancel {
    background-color: #f4d4d2 !important;
    border: 2px solid #c01818;
  }
}
