@keyframes slide-in {
  0% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0);
  }
}

#orders {
  height: 100vh;
  overflow-y: hidden;
  // Workaround for iOS "dynamic" viewport
  // https://newbedev.com/css-media-query-to-target-only-ios-devices
  @supports (-webkit-touch-callout: none) {
    // height: calc(100vh - 75px);
    -webkit-overflow-scrolling: touch;
  }

  display: flex;
  flex-direction: column;
  width: 100%;

  > .content {
    padding: 0 20px;
    height: 100%;
    overflow-x: hidden;
    display: flex;

    > .board {
      flex-basis: 65%;
      overflow-y: auto;
    }
    > .selected-order-container {
      flex-basis: 40%;
      padding: 20px 0;
    }
  }

  .data-container {
    background: white;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    padding: 1rem;
    overflow-y: auto;
    &.no-padding {
      padding: 0;
    }
  }
}

#completed-orders {
  .delivery-date-input {
    padding-top: 1rem;
    white-space: nowrap;
    padding-right: 1rem;
  }
}

#incoming-orders,
#preparation-orders,
#ready-orders,
#completed-orders {
  padding-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  .selectable-row {
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
      background-color: #dbdbff;
    }
  }

  .order-row {
    display: flex;
    align-items: center;
    background: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 5px;
    padding: 1rem 2rem;
    height: 80px;
    margin: 0 5px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: transform 0.5s;
    position: relative;

    .new-order-ping {
      position: absolute;
      left: 10px;
    }

    &.selected {
      transform: translateX(1rem);
      background-color: #c1d7ff !important;
    }
    &.canceled {
      opacity: 0.4;
      .cancel-status {
        height: fit-content;
        padding: 0.3rem 0.8rem;
        font-size: 1.2rem;
        border-radius: 4px;
        color: white;
        font-weight: 800;
        align-self: center;
        background-color: #bc6767;
      }
    }

    .number {
      font-size: 3rem;
      align-self: center;
      flex-basis: 4rem;
    }
    .customer {
      font-weight: 700;
      font-size: 1.2rem;
    }
    .action-buttons {
      display: flex;
      margin-left: auto !important;
    }

    .status {
      align-self: center;
      margin-left: auto;
    }
  }
}
.selected-order {
  flex-basis: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  animation: slide-in 0.5s;
  -webkit-animation: slide-in 0.5s;

  > .header {
    height: 70px;
    padding: 0 20px;
    background-color: #4a4e69;
    color: white;
    position: sticky;
    top: 0;
    z-index: 1;
    text-align: center;
    box-shadow: rgb(0 0 0 / 24%) 0px 4px 8px;

    .order-number {
      font-size: 2.5rem;
    }

    .cancel-status {
      height: fit-content;
      padding: 0.3rem 0.8rem;
      font-size: 1.2rem;
      border-radius: 4px;
      color: white;
      font-weight: 800;
      align-self: center;
      background-color: #bc6767;
    }
  }
  > .actions {
    height: 70px;
    width: 100%;
    padding: 10px;
    border-top: 1px solid #e6e6e6;
    text-align: right;
    background-color: white;
    display: flex;
    flex-direction: row-reverse;

    > .product-count-indicator {
      font-size: 1.2rem;
      color: #5e5e5e;
    }
  }
  > .content {
    height: calc(100% - 140px);
    padding-top: 1rem;
    overflow-y: auto;
    > .product-row {
      margin-bottom: 20px;
      .product-quantity {
        font-weight: bold;
        display: inline-block;
        width: 30px;
      }
    }
  }
}

#order-type-navigation {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  z-index: 1;

  > h3 {
    margin: 0;
    width: 300px;
  }

  > .order-types {
    display: flex;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    > .order-type {
      $color: #b1b1b1;
      background-color: #e0e0e0;
      color: $color;
      width: 100%;
      height: 70px;
      border-right: 1px solid #a3a3a3;
      transition: opacity 0.3s, background-color 0.3s, opacity 0.3s, color 0.3s;
      font-weight: 500;
      font-size: 1.5rem;

      display: flex;
      justify-content: center;
      align-items: center;

      > svg {
        height: 45px;
        padding: 2px;

        path {
          transition: fill 0.3s;
          fill: $color;
        }
      }

      &.active {
        background-color: #434358;
        opacity: 1;
        color: white;
        > svg {
          path {
            fill: white;
          }
        }
      }
    }
  }
}

.order-products {
  display: flex;
  flex-direction: column;
  width: 100%;
  $summary-padding: 0.5rem 1rem;

  .restaurant-products {
    overflow-y: auto;
    .restaurant {
      .title {
        padding: $summary-padding;
        font-size: 1rem;
        .name {
          font-size: 1.5rem;
          font-weight: 700;
          margin-left: 0.3rem;
        }
      }

      .product {
        font-size: 1.4rem;
        transition: background-color 0.5s;
        padding: $summary-padding;

        .count {
          background-color: #22223b;
          width: 30px;
          height: 30px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.1rem;
          font-weight: 700;
        }

        .name {
          font-size: 1.2rem;
        }

        .product-checkbox {
          margin-left: auto;
          width: 30px;
          height: 30px;
          border: 3px solid #84c191;
          border-radius: 5px;
          position: relative;

          .progress-background {
            height: 100%;
            width: 100%;
            font-size: 1.1rem;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #d4e8d9;
            > :nth-child(n) {
              margin: 0;
            }
          }
          .valid-background {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #84c191;
            > :nth-child(n) {
              margin: 0;
            }
          }
        }

        .item-additionnal-information {
          color: #0008;
          padding-left: 0.5rem;
          font-size: 1rem;
          gap: 0.3rem;
          border-left: solid 1px #909090;
          padding: 0.4rem;
          margin-left: 1rem;

          .item-options {
            font-weight: 700;
          }

          .option-choices {
            font-weight: 400;
          }

          .comments {
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  &.inverted {
    color: #ededed;

    .count {
      background-color: #d2d5d9 !important;
      color: #1f345b !important;
      font-weight: 500;
    }
    .separator {
      opacity: 0.2;
    }
  }
}

.order-status {
  border-radius: 20px;
  border: 3px solid white;
  padding: 0.3rem 1rem;
  font-weight: 500;
}
