.column {
  display: flex;
  flex-direction: column;

  &.children-margin {
    &.tiny {
      > :nth-child(n) {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
      }
    }
    &.small {
      > :nth-child(n) {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
    &.medium {
      > :nth-child(n) {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
    &.large {
      > :nth-child(n) {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }

    > :first-child {
      margin-top: 0 !important;
    }
    > :last-child {
      margin-bottom: 0 !important;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;

  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.children-margin {
    &.tiny {
      > :nth-child(n) {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }
    &.small {
      > :nth-child(n) {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
    &.medium {
      > :nth-child(n) {
        margin-left: 2rem;
        margin-right: 2rem;
      }
    }
    &.large {
      > :nth-child(n) {
        margin-left: 3rem;
        margin-right: 3rem;
      }
    }

    > :first-child {
      margin-left: 0 !important;
    }
    > :last-child {
      margin-right: 0 !important;
    }
  }
}

.separator {
  border-top: 1px solid #e6e6e6;

  &.vertical {
    height: auto;
    margin: 0 2rem;
    border-top: none;
    border-left: 1px solid #e6e6e6;
  }
}

.clickable {
  cursor: pointer;
}

.hoverable {
  transition: background 0.1s;
  &:hover {
    background: #eaf7ff !important;
  }
}

.min-height {
  &.medium {
    min-height: 30rem;
  }
}

.no-data {
  opacity: 0.3;
}

.ui.dimmer .ui.workaround-modal-loader.loader:before {
  border-color: rgba(0, 0, 0, 0.1);
}

.ui.dimmer .ui.workaround-modal-loader.loader:after {
  border-color: #767676 transparent transparent;
}

.ui.dimmer .ui.workaround-modal-loader.loader {
  color: black;
}

@keyframes grow-and-fade {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(2);
  }
}

.ping {
  $light-background-color: #b6b6f1;
  $dark-background-color: #3d3d7a;
  background-color: $dark-background-color;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  position: relative;
  flex-shrink: 0;
  .animated {
    position: absolute;
    background-color: $dark-background-color;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    animation: grow-and-fade 1s infinite;
  }
  &.slow {
    .animated {
      animation: grow-and-fade 1.5s infinite;
    }
  }
  &.fast {
    .animated {
      animation: grow-and-fade 0.5s infinite;
    }
  }
  &.fastest {
    .animated {
      animation: grow-and-fade 0.3s infinite;
    }
  }

  &.inverted {
    background-color: $light-background-color;
    .animated {
      background-color: $light-background-color;
    }
  }
}

.countdown {
  height: fit-content;
  padding: 0.3rem 0.8rem;
  font-size: 1.2rem;
  border-radius: 4px;
  background-color: #67bc75;
  color: white;
  font-weight: 800;

  align-self: center;
  &.nearly-late {
    background-color: #bca667;
  }
  &.late {
    background-color: #bc6767;
  }
}

div:has(.start-indications) {
  position: relative;
}
.start-indications {
  background-color: #626296;
  position: absolute;
  left: 110px;
  width: max-content;
  padding: 1rem 2rem;
  padding-left: 1rem;
  font-size: 1.4rem;
  border-radius: 2rem;
  animation: indicate 2s ease infinite;
}

@keyframes indicate {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(20px);
  }
  60% {
    transform: translateX(15px);
  }
}
